import { createMuiTheme } from '@material-ui/core';

export const primaryColor = '#d61037';
export const secondaryColor = '#000000';
export const lightGreyColor = '#4d4d4d';
export const lightGreyColor2 = '#bfbfbf';
export const lightPinkColor = '#fff7f9';
export const whiteColor = '#ffffff';

const xsWidth = 0;
const smWidth = 768;
const mdWidth = 1200;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: whiteColor,
    },
    secondary: {
      main: secondaryColor,
      light: lightGreyColor,
    },
    text: {
      secondary: lightGreyColor,
    },
    colors: {
      primaryColor,
      secondaryColor,
      whiteColor,
      lightGreyColor,
      lightGreyColor2,
      lightPinkColor,
    },
  },
  typography: {
    fontFamily: ['Montserrat'],
  },
  breakpoints: {
    values: {
      xs: xsWidth,
      sm: smWidth,
      md: mdWidth,
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 600,
        fontSize: 80,
        '@media (max-width: 767px)': {
          fontSize: 37,
        },
      },
      h2: {
        fontWeight: 800,
        fontSize: 61,
        '@media (max-width: 767px)': {
          fontSize: 38,
        },
      },
      h3: {
        fontWeight: 500,
        fontSize: 56,
        '@media (max-width: 767px)': {
          fontSize: 38,
        },
      },
      h4: {
        fontSize: 30,
        '@media (max-width: 1199px)': {
          fontSize: 22,
        },
        '@media (max-width: 767px)': {
          fontSize: 19,
        },
      },
      h5: {
        fontWeight: 500,
        lineHeight: 1.54,
        fontSize: 26,
        '@media (max-width: 767px)': {
          fontSize: 12,
        },
      },
      h6: {
        '@media (max-width: 767px)': {
          fontSize: 13,
          lineHeight: '17px',
        },
      },
      body1: {
        '@media (max-width: 767px)': {
          fontSize: 11,
          lineHeight: '15px',
        },
      },
      body2: {
        fontWeight: 500,
        color: lightGreyColor,
        fontSize: 28,
        '@media (max-width: 767px)': {
          fontSize: 16,
        },
      },
      subtitle1: {
        fontSize: 18,
        '@media (max-width: 1199px)': {
          fontSize: 16,
        },
        '@media (max-width: 767px)': {
          fontSize: 11,
          lineHeight: '15px',
        },
      },
      subtitle2: {
        fontSize: 32,
        '@media (max-width: 767px)': {
          fontSize: 18,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      contained: {
        color: 'white',
        padding: '14px 86px',
        borderRadius: 33,
        fontSize: 22,
        '@media (max-width: 767px)': {
          fontSize: 11,
          borderRadius: 47,
          padding: 'initial',
          width: 137,
          height: 36,
          marginBottom: 15,
        },
      },
      outlined: {
        color: 'white',
        padding: '14px 46px',
        borderRadius: 33,
        borderWidth: '2px !important',
        fontSize: 22,
        '@media (max-width: 767px)': {
          fontSize: 11,
          borderRadius: 47,
          padding: '14px 26px',
          height: 36,
          marginBottom: 15,
        },
      },
      containedSizeSmall: {
        padding: '11px 35px',
        borderRadius: 27,
        fontSize: 18,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 12,
        fontSize: 24,
        '@media (max-width: 767px)': {
          fontSize: 16,
        },
        padding: '0 !important',
      },
      input: {
        padding: '25px 42px',
        '@media (max-width: 767px)': {
          padding: '20px 22px',
        },
      },
      inputMultiline: {
        padding: '25px 42px',
        '@media (max-width: 767px)': {
          padding: '20px 22px',
        },
      },
    },
  },
});

export default theme;
