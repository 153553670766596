// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gardening-js": () => import("./../../../src/pages/gardening.js" /* webpackChunkName: "component---src-pages-gardening-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lawn-care-js": () => import("./../../../src/pages/lawn-care.js" /* webpackChunkName: "component---src-pages-lawn-care-js" */),
  "component---src-pages-stonework-js": () => import("./../../../src/pages/stonework.js" /* webpackChunkName: "component---src-pages-stonework-js" */),
  "component---src-pages-trees-js": () => import("./../../../src/pages/trees.js" /* webpackChunkName: "component---src-pages-trees-js" */)
}

